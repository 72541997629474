import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { graphql } from "gatsby";
import { trackingGA } from "@merchx-v2/helpers";
import { useSiteMetadata } from "hooks/use-site-metadata";
import { Footer, Header, PoliciesMenu, SEO, VipClub } from "components";
import {
  actionCreators as actionHomeCreators,
  selectMenu,
} from "state/HomePage/reducer";

import * as styles from "./MembershipPage.module.scss";

const MembershipPage = ({
  menu,
  resetMenuFilters,
  setMenuCollection,
  data,
}) => {
  const { shared } = useSiteMetadata();

  const subscriptionPrices = shared.SUBSCRIPTION_PRODUCT;

  const [showButtonClass, setShowButtonClass] = useState("buttonToTopHidden");

  const onScroll = (e) => {
    if (window.pageYOffset > 100) {
      setShowButtonClass("buttonToTop");
    } else {
      setShowButtonClass("buttonToTopHidden");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    window.scrollTo(0, 0);
    trackingGA();

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const handleCollectionClicked = (collection) => {
    setMenuCollection(collection);
  };

  const handleReset = () => {
    resetMenuFilters();
  };

  const { SEO_TITLE, SEO_DESCRIPTION, SEO_KEYWORDS } =
    data?.markdownRemark?.frontmatter || {};

  return (
    <div className={styles.membershipPage}>
      <SEO
        title={SEO_TITLE}
        description={SEO_DESCRIPTION}
        keywords={SEO_KEYWORDS}
        pathname="/membership"
      />
      <Header
        menu={menu}
        onCollectionClicked={handleCollectionClicked}
        onResetCollection={handleReset}
      />
      <div className={styles.content}>
        <PoliciesMenu />
        <VipClub />
        <button
          className={styles[showButtonClass]}
          onClick={(e) => window.scroll(0, 0)}
        >
          Back to top
        </button>
      </div>
      <div className={styles.footerContainer}>
        <Footer handleReset={handleReset} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  menu: selectMenu(state),
});
const mapDispatchToProps = {
  resetMenuFilters: actionHomeCreators.resetMenuFilters,
  setMenuCollection: actionHomeCreators.setMenuCollection,
};

export default connect(mapStateToProps, mapDispatchToProps)(MembershipPage);

export const query = graphql`
  query MembershipPage {
    markdownRemark(fields: { slug: { eq: "/membership/" } }) {
      frontmatter {
        TITLE
        SEO_TITLE
        SEO_DESCRIPTION
        SEO_KEYWORDS
      }
    }
  }
`;
